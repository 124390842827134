<div class="head" [ngClass]="getHeadClass()">
    <div class="head-content">
      <div class="head-content-left">
        <div class="search-container">
          <div *ngIf="!canShowSearchAsOverlay" class="normal-search">
            <input type="search" name="" id="" class="search" placeholder="Suchen...">
            <i class="far fa-search search-icon"></i>
          </div>
          <div *ngIf="canShowSearchAsOverlay">
            <button
              [cdkMenuTriggerFor]="searchOverLay"
              class="search-menu-button ripple"
            >
              <i class="far fa-search search-icon"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="head-content-rigt">
        <div class="head-menu">
          <select class="form-select" [(ngModel)]="selected" (change)="onChange($event.target.value)" aria-label="Default select example">
            <option *ngFor="let loc of myLocations" value="{{loc.key}}">{{loc.name}}</option>
          </select>
          <!-- <div class="head-menu-item">
            <button class="head-menu-item-btn ripple">
              <img [src]="'./assets/flags/aut.png'" alt="flag" class="flag">
            </button>
          </div> -->
          <!-- <div class="head-menu-item">
            <button class="head-menu-item-btn ripple">
              <i class="far fa-expand menu-icon"></i>
            </button>
          </div>
          <div class="head-menu-item">
            <button class="head-menu-item-btn ripple">
              <i class="far fa-bell menu-icon"></i>
            </button>
          </div>
          <div class="head-menu-item">
            <button class="head-menu-item-btn ripple">
              <i class="far fa-comment-alt menu-icon"></i>
            </button>
          </div>
        </div> -->
        <div class="head-user-avatar-container">
          <div class="head-user-avatar">
            <img src="../../assets/img/default_user.png" alt="user" class="user-avatar">
            <!-- <div class="circle-indicator pulse"></div> -->
          </div>
        </div>
      </div>
    </div>



</div>

<ng-template #searchOverLay>
  <div class="overlay overlay-search overlay-fall" cdkMenu>
    <input type="search" class="search" name="" id="" placeholder="Search...">
    <button class="search-button">
      <i class="far fa-search search-icon"></i>
    </button>
  </div>

</ng-template>
