<div class="container">
  <div class="row">
    <div class="col d-flex justify-content-end">
      <div class="card"  style="width:15%">
        <div class="card-head kopfCenter">
            Gedruckte Bons
        </div>
        <div class="card-body umsatz">
          {{bonNR}}
        </div>
      </div>
      <div class="card"  style="width:15%">
        <div class="card-head kopfCenter">
          Aktive Kellner
        </div>
        <div class="card-body umsatz">
          4
        </div>
      </div>
      <div class="card" style="width:70%">
        <div class="card-head kopfCenter">
          Umsatz
        </div>
        <div class="card-body umsatz">
          252,55 €
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="card"  style="width:100%">
      <div class="card-head kopfCenter">
        Aktive Drucker
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col" *ngFor="let druck of drucker |keyvalue">
              <div class="druckername">{{druck.key}}</div><div class="druckerip">{{druck.value}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
