import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { DbService } from '../services/db.service';





@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})


export class UserComponent implements OnInit {

  users = [];
  form = this.fb.group({
    token: ['',[Validators.required,Validators.minLength(6),]],
    name: ['',[Validators.required, Validators.minLength(3)]],
    vorname: ['',[Validators.required, Validators.minLength(3)]],
  });

  hash:string = '';
  loc = localStorage.getItem('location');

  constructor(
    public fb: FormBuilder,
    public db:DbService
  ){
  }

  async ngOnInit() {
    await this.getUsers();
    this.kellner();
  };

  getUsers(){
    return new Promise(resolve=>{

      this.db.getMyUsers(this.loc).snapshotChanges().subscribe((res)=>{
        let x = 0;
        console.log(res);
        this.users = [];
        res.forEach(item=>{
          x++;
          let content = item.payload.toJSON();
          this.users.push({key:item.key, name:content['name'],vorname:content['vorname'],aktiv:content['active'],umsatz:0 });
          if(x == res.length)resolve(true);
        });
        console.log(this.users);
        this.kellner();
      })
    })

  }

  onSubmit(){
    if(this.form.valid){
      this.db.addKellner(this.form.value,this.loc);
    }
  }
  async newHash(){
    let msg = this.makeid(8);
      this.form.setValue({
        name:'',
        vorname:'',
        token:msg
    });

  }
  makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789?!';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
      };
      return result;
  }
  kellnerums = {};

  async kellner(){
    await new Promise((resolve)=>{
      let x = 0;
      this.users.forEach(item=>{
        x++;
        console.log(x);
        this.kellnerums[item.key] = {};
        this.kellnerums[item.key]['umsatz'] = 0;
        if(this.users.length == x) resolve(true);
      })
    })
    this.db.getKellnerumsätze(this.loc).snapshotChanges().subscribe((res)=>{
      res.forEach((item)=>{
        let umsaetze = item.payload.toJSON();
        console.log(umsaetze);
        let x = 0;
        let umsatz = 0;
        Object.keys(umsaetze).forEach((key)=>{
          x++;
          umsatz = umsatz + umsaetze[key]['betrag'];
          if(x == Object.keys(umsaetze).length){
            // this.kellnerums[item.key] = {};
            // this.kellnerums[item.key]['umsatz'] = umsatz;
            this.users.forEach((res,index) =>{
              if(res.key == item.key){
                this.users[index].umsatz = umsatz;
              }
            })
          }
        })
      });
      console.log(this.kellnerums)
    })
  }

  lockKellner(status,uid){
    console.log(status,'status');
    console.log(uid,'uid')
    this.db.lockKellner(status,uid,this.loc)
  }
  deleteKellner(uid){
    if(this.kellnerums[uid]['umsatz'] == 0){
      this.db.deleteKeller(uid,this.loc)
    }
  }
}
