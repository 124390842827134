<section class="h-100">
  <div class="container h-100">
    <div class="row justify-content-sm-center h-100">
      <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
        <div class="text-center my-5">
          <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" alt="logo" width="100">
        </div>
        <div class="card shadow-lg">
          <div class="card-body p-5">
            <h1 class="fs-4 card-title fw-bold mb-4">Login</h1>
            <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
              <div class="form-floating mb-3">
                <!-- <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="email" class="form-control" [ngclass]="{submitted && f.email.errors ? 'is-invalid : 'is-invalid'}"  id="inputEmail4"> -->
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && (f.email.errors || !emailCorrect) }" [ngClass]="{ 'is-valid': submitted && emailCorrect }"  id="inputEmail4">
                <div *ngIf="submitted && (f.email.errors)" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Ungültige email Adresse!</div>
                </div>
                <div *ngIf="submitted && (!email)" class="invalid-feedback">
                  <div [hidden]="email">{{emailContent}}</div>
               </div>
                <label for="floatingInput">Email address</label>
              </div>
              <div class="form-floating mb-3">
                <input (focus)="submitted = false" type="password" class="form-control" id="floatingPassword" placeholder="Password" formControlName="pwd"[ngClass]="{ 'is-invalid': submitted && (f.pwd.errors || !pwCorrect) }" [ngClass]="{ 'is-valid': submitted && pwCorrect}" id="inputPassword4">
                <div *ngIf="(submitted && (f.pwd.errors != null))" class="invalid-feedback">
                  <div *ngIf="f.pwd.errors.required">Password wird benötigt</div>
                  <div *ngIf="pwShort">Passwort zu kurz</div>
                </div>
                <div  class="invalid-feedback" *ngIf ="!pwCorrect">Falsches Passwort</div>
                <label for="floatingPassword">Password</label>
              </div>

              <div class="d-grid">
                <button class="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">Anmelden</button>
                <div class="text-center">
                  <a class="small" routerLink="/forgot-password">Passwort vergessen?</a>
                </div>
              </div>

            </form>
          </div>
          <div class="card-footer py-3 border-0">
            <div class="text-center">
              Keinen Account? <a  routerLink="/register-user" class="text-dark">Registrieren</a>
            </div>
          </div>
        </div>
        <div class="text-center mt-5 text-muted">
          Copyright &copy; 2017-2021 &mdash; Your Company
        </div>
      </div>
    </div>
  </div>
</section>
