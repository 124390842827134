import { Component, OnInit, HostListener } from '@angular/core';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {


  loc = 'pqnCQ2KCRJx3zN2Ln6UPPLSj';
  drucker = {};
  bonNR=0;

    constructor(
      private db:DbService
    ) {
      this.loc = localStorage.getItem('location');
    }
  async ngOnInit() {

    this.printers();
    this.allBons();
  }

  printers(){
    this.db.getPrinterData(this.loc).snapshotChanges().subscribe((res)=>{
      res.forEach((item)=>{
        console.log(item.payload.toJSON(),item.key);
        let payload = item.payload.toJSON();
        // if( this.drucker[item.key]== undefined ) this.drucker[item.key] = {};
        this.drucker[payload['name']] = payload['ip'];

      })
    })
  }

  allBons(){
    this.db.getAllMyBons(this.loc).snapshotChanges().subscribe(res=>{
      this.bonNR = 0;
      res.forEach(item=>{
        let payload = item.payload.toJSON();
        console.log(payload);
        this.bonNR = this.bonNR + Object.keys(payload).length
      })
    })
  }


}
