import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    private db:AngularFireDatabase
  ) { }

  getAllPayments(loc, printer){
    if(printer == 'alle'){
      return this.db.list('projects/'+loc+'/Payments/komplett');
    }else{
      return this.db.list('projects/'+loc+'/Payments/verkauft/'+printer);
    }

  }

  getAllPrinters(loc){
    return new Promise((resolve,reject)=>{
      let printers = [];
      console.log('aufruf');
      let x = this.db.list('projects/'+loc+'/Payments/verkauft');
      x.snapshotChanges().subscribe((res)=>{
        printers= [];
        let x = 0;
        // console.log(res.);
        res.forEach((item)=>{
          x++;
          printers.push(item.key);
          if(x == res.length){
            resolve(printers);
          }
          // console.log(item.key);
        });
        // console.log(printers);
      })
    })
  }
  getPrinterData(loc){
      return this.db.list('projects/'+loc+'/printer');
  }

  getKellnerumsätze(loc){
    return this.db.list('projects/'+loc+'/Payments/user');
  }

  getKellnerName(loc){
    return this.db.list('location/'+loc+'/user');
  }

  getMyProjects(){
    let user = JSON.parse(localStorage.getItem('user'));
    user = user.uid
    return this.db.list('user/'+user+'/location')
  }

  getMyUsers(loc){
    // let loc = localStorage.getItem('location');
    console.log(loc);
    return this.db.list('projects/'+loc+'/user')
  }

  getAllMyBons(loc){
    return this.db.list('projects/'+loc+'/bon/new');
  }

  lockKellner(status,uid,loc){
    let state = '';
    if(status == 'true') state = 'false';
    if(status == 'false') state = 'true';
    return this.db.object('projects/'+loc+'/user/'+uid).update({active: state})
  }

  deleteKeller(uid,loc){
    this.db.object('projects/'+loc+'/user/'+uid).remove();
  }

  addKellner(value,loc){
    console.log(value);
    this.db.list('location/'+loc+'/user').update(value.token,{name:value.name,vorname:value.vorname,active:'false',uid:value.token});
    this.db.list('projects/'+loc+'/user').update(value.token,{name:value.name,vorname:value.vorname,active:'false',uid:value.token});
  }
}
