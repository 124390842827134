export const navbarData = [
  {
    routerLink: "dashboard",
    icon: 'fal fa-home',
    label: 'Dashboard'
  },
  {
    routerLink: "statistics",
    icon: 'fal bi bi-bar-chart-fill',
    label: 'Statistics'
  },
  {
    routerLink: "user",
    icon: 'fal bi bi-people-fill',
    label: 'Benutzerverwaltung'
  }
]

