<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''">
  <div class="logo-container">
    <button class="logo"(click)="toggleCollapse()">OE</button>
    <div class="logo-text" *ngIf="collapsed">OrderExpress</div>
    <button class="xerl" *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fal fa-times close-icon"></i>
    </button>
  </div>
  <ul class="sidenav-nav">
    <li class="sidenav-nav-item" *ngFor="let data of navData">
      <a href="" class="sidenav-nav-link"
        [routerLink]="[data.routerLink]"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}"
      >
        <i class="sidenav-link-icon" [class]="data.icon"></i>
        <span class="sidenav-link-text" *ngIf="collapsed">{{data.label}}</span>
      </a>
    </li>
  </ul>
  <ul class="sidenav-nav">
    <li class="sidenav-link-item"(click)="authS.SignOut()">
      <i class="sidenav-link-icon bi bi-box-arrow-left"></i>
      <span class="sidenav-link-text">Logout</span>
    </li>
  </ul>
  <ul class="sidenav-nav">
    <li class="sidenav-link-item"(click)="authS.SignOut()">
      <i class="sidenav-link-icon bi bi-box-arrow-left"></i>
      <span class="sidenav-link-text">Logout</span>
    </li>
  </ul>
</div>
