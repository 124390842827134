import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() collapsed = false;
  @Input() screenwidth = 0;


  selected = '';
  canShowSearchAsOverlay = false;
  myLocations = [];
  positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }
    ),
  ];

  constructor(
    private db:DbService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.checkCanShowSearchAsOverlay(window.innerWidth);
  }

  ngOnInit(){
    this.selected = localStorage.getItem('location');
    this.checkCanShowSearchAsOverlay(window.innerWidth);
    this.db.getMyProjects().snapshotChanges().subscribe((res)=>{
      res.forEach((item)=>{
        this.myLocations.push({key: item.key,name:(item.payload.toJSON())['name']})
      })
    })

  }

  getHeadClass():string{
    let styleclass = '';
    if(this.collapsed && this.screenwidth > 786){
      styleclass = 'head-trimmed';
    }else{
      styleclass = 'head-md-screen';
    }
    return styleclass;
  }

  checkCanShowSearchAsOverlay(innerWidth:number){
    if(innerWidth < 845){
      this.canShowSearchAsOverlay = true;
    }else{
      this.canShowSearchAsOverlay = false;
    }
  }

  onChange(eve){
    console.log(eve);
    localStorage.removeItem('location')
    localStorage.setItem('location', eve);
    location.reload();
  }

}
